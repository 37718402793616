import { IMAGES_DELETE } from '../lib/api/delete_image_api';
import { IMAGES_POST } from '../lib/api/post_image_api';
import { PUT_EXPRESSION_API } from '../lib/api/put_expression_api';
import { IMAGES_PUT } from '../lib/api/put_image_api';
import { TRANSLATIONS_PUT } from '../lib/api/put_translation_api';

export const getIndex = (
  setIsEnd: Function,
  setNextData: Function,
  setIsFirst: Function,
  setPrevData: Function,
  location: any,
  currentIdx: number
) => {
  if (!location.state.items[currentIdx + 1]) {
    setIsEnd(true);
  } else {
    setNextData(location.state.items[currentIdx + 1]);
  }
  if (!location.state.items[currentIdx - 1]) {
    setIsFirst(true);
  } else {
    setPrevData(location.state.items[currentIdx - 1]);
  }
};

export const checkPrevNextButton = (
  currentIdx: number,
  setIsEnd: Function,
  setIsFirst: Function,
  location: any
) => {
  setIsEnd(currentIdx + 1 === location.state.items.length ? true : false);
  setIsFirst(currentIdx === 0 ? true : false);
};

export const checkAdmin = (setAdmin: Function) => {
  const user = localStorage.getItem('userId');
  if (typeof user === 'string') {
    const userData = JSON.parse(user) as { is_admin: boolean };
    setAdmin(userData.is_admin);
  }
};

export const restoreExpression = (
  id: string
) => PUT_EXPRESSION_API({id: Number(id), deleted: false});

export const originalTextModification = (
  id: string,
  key?: string,
  text?: string,
  description?: string,
  singular?: string,
) => PUT_EXPRESSION_API({id: Number(id), key: key, text: text, description: description, singular: singular});

export const textModification = (text: string, translation: any, setRevisedData: Function) => {
  TRANSLATIONS_PUT(text, translation)
    .then((res) => {
      setRevisedData(res);
    })
    .catch((err) => alert(err));
};

export const deleteImage = (imageId: number) => {
  // eslint-disable-next-line no-restricted-globals
  if (confirm('Do you really want to delete the image?') === false) {
    return;
  }
  IMAGES_DELETE(imageId)
    .then(() => {})
    .catch((err) => alert(err.message));
};

export const updateImageUpload = (imageId: number, desc: string, setRevisedImage: Function) => {
  IMAGES_PUT(imageId, desc)
    .then((res) => {
      setRevisedImage(res);
    })
    .catch((err) => alert(err.message));
};

export const imageUpload = (
  setLoading: Function,
  setRevisedImage: Function,
  file: File,
  id: number,
  newImageDesc: string
) => {
  let reader = new FileReader();
  reader.onloadend = () => {
    if (reader.result) {
      setLoading(true);
      IMAGES_POST(id, newImageDesc, {
        data: reader.result!,
        contentType: file.type,
        extension: file.name.split('.').pop()!,
      })
        .then((res) => {
          setRevisedImage(res);
          setLoading(false);
        })
        .catch((err) => {
          alert(err.message);
          setLoading(false);
        });
    }
  };
  if (file) {
    reader.readAsArrayBuffer(file);
  } else alert('Select an image and press it.');
};
