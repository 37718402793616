import { endpoint } from './endpoint';

type ImageData = {
  data: string | ArrayBuffer,
  contentType: string,
  extension: string,
}

export const IMAGES_POST = async (id: number, desc: string, { data, contentType, extension }: ImageData) => {
  function arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const base64Data = typeof data === 'string' ? data : arrayBufferToBase64(data as ArrayBuffer);
  const response = await fetch(`${endpoint}/images`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({
      data: base64Data,
      contentType,
      extension,
      description: desc,
      expression_id: id,
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    const message = `An error has occurred ${response.status}: ${error.message}`;
    throw new Error(message);
  }

  return await response.json();
};
